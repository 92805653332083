export const listData =[
    {
        topic: "Expertise and Experience",
        // explanation:" Our team is composed of seasoned professional women who bring a wealth of knowledge and hands-on experience across various industries."
    },
    {
        topic: "Tailored Solutions",
        // explanation:" We recognize that every business is unique. Our approach is to listen first, then tailor our services to meet your specific needs and objectives."
    },
    {
        topic: "Innovative Thinking",
        // explanation:" In a rapidly changing business landscape, staying ahead requires innovative thinking."
    },
    {
        topic: "Commitment to Quality",
        // explanation:"We pride ourselves on the quality of our work, from the strategies we develop to the support we provide."
    },
    {
        topic: "Long-Term Partnerships",
        // explanation:" Our success is measured by your success. We seek to build long-term relationships that support your growth and evolution over time."
    },
]
