export const TeamData = [
  {
    id: 1,
    name: "Serah Muthoni KAMAU",
    role: "Founder & CEO",
    country: "Kenya",
    bio: "Passionate visionary committed to innovation and excellence.",
    img: process.env.PUBLIC_URL + "/Assets/BigImages/avatar1.png",
  },
  {
    id: 2,
    name: "Soraya Haidary",
    role: "CTO",
    country: "Afghanistan",
    bio: "Pioneering advancements in tech with a global perspective.",
    img: process.env.PUBLIC_URL + "/Assets/BigImages/avatar5.png",
  },

  {
    id: 5,
    name: "Aenia Amin",
    role: "Lead Data Analyst",
    country: "USA",
    bio: "Harnessing the power of data to drive informed decision-making.",
    img: process.env.PUBLIC_URL + "/Assets/BigImages/Aenia.jpeg",
  },
  {
    id: 9,
    name: "Nora Oyiengo",
    role: "Lead UI/UX Designer",
    country: "Kenya",
    bio: "Lead UI/UX designer with strategic planning and engaging user interface creation.",
    img: process.env.PUBLIC_URL + "/Assets/BigImages/nora.jpg",
  },
 
  
  
];
